/**
 * Photo Component
 * 
 * An individual photo element, used in the Photo Galleries
 */

import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import SVGLoader from '../svg/loader'
import { getStaticUrl, getMediaUrl } from '../../utils/helpers'

const Photo = ({photo, onClick, selected=false}) => {
  const [src, setSrc] = useState(() => getMediaUrl(photo.image))
  const [loading, setLoading] = useState(true)
  const [blockHeight, setBlockHeight] = useState(0)
  let blockElem = React.createRef()

  useEffect(() => {
    let height = blockElem.clientWidth
    setBlockHeight(height)
  }, [blockElem.clientWidth])

  useEffect(() => {
    const imageLoader = new Image()
    imageLoader.src = getMediaUrl(photo.image)
    imageLoader.onload = () => {
      setSrc(getMediaUrl(photo.image))
      setLoading(false)
    }
  }, [photo.image])

  return (
    <div
      className={`photo-container`}
      ref={elem => {
        blockElem = elem
      }}>
      <button
        onClick={() => {
          onClick(photo)
        }}
        className={`btn btn-link btn-photo px-0 py-0 d-block w-100 ${selected ? `selected` : ``}`} title="View Larger">
        <div className={`img-holder`} style={{ height: blockHeight }}>
          {loading ? (
            <div className={`d-flex align-items-center h-100 justify-content-center text-primary`}>
              <SVGLoader className={`text-primary`} width={20} height={20} />
            </div>
          ) : (
            <div
              className={`img-preview px-0`}
              style={{
                height: blockHeight,
                backgroundImage: `url(${src})`,
              }}></div>
          )}
          <p className={`mb-0 sr-only`}>{photo.description}</p>
          <small className={`sr-only`}>Uploaded by {photo.author}</small>
        </div>
      </button>
    </div>
  )
}

Photo.propTypes = {
  photo: PropTypes.object,
  onClick: PropTypes.func,
}

export default Photo