/**
 * Host Photo Component
 * 
 * Display a photo item that the host uploaded, and also allows the host to delete this item.
 */

import React, { useState, useEffect, useContext } from 'react'
import HostContext from '../../../utils/host-context'
import SvgTrashcan from '../../svg/trashcan'
import { getMediaUrl } from '../../../utils/helpers'

const HostPhoto = ({photo, onClick}) => {
  const context = useContext(HostContext)
  const name = context.user.first_name + ` ` + context.user.last_name
  return (
    <div key={photo.id} className={`position-relative px-2`}>
      <img className="img-fluid" src={getMediaUrl(photo.image)} />
      {photo.author == name &&
      <button className={`position-absolute btn btn-trash`} onClick={() => onClick(photo)} title="Delete">
        <SvgTrashcan />
      </button> }
    </div>
  )
}

export default HostPhoto