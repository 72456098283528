import React from 'react'

const SvgTrashcan = props => (
  <svg width={30} height={28} viewBox="0 0 30 28" {...props}>
    <g fill="none" fillRule="evenodd">
      <ellipse cx="14.689" cy="13.824" fill="#AB3733" rx="14.689" ry="13.824" />
      <path
        fill="#FFF"
        d="M13.147 5l-.437 1.317H8v1.317h14V6.317h-4.742L16.853 5h-3.706zM9.235 8.512V23h11.53V8.512H9.235zm2.883 1.756h.823v10.976h-.823V10.268zm2.47 0h.824v10.976h-.824V10.268zm2.47 0h.824v10.976h-.823V10.268z"
      />
    </g>
  </svg>
)

export default SvgTrashcan
