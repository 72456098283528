/**
 * Host Gallery Page
 * 
 * Allows the hosts to see the photos uploaded to a particular hunt.
 * This can be used for hosts to determine if they should give bonus points
 * to a particular team or not.
 */

import React, { useState, useEffect, useContext } from 'react'
import HostContext from '../../utils/host-context'
import HostLoginWrapper from '../../components/host/login-wrapper'
import API from '../../utils/api'

import Photo from '../../components/photos/photo'
import UploadPhotoModal from '../../components/host/photos/upload-modal'
import PhotoUpload from '../../components/host/photos/photo-upload'
import HostPhoto from '../../components/host/photos/host-photo'

import Slider from 'react-slick'

import Modal from 'react-bootstrap/Modal'
import SVGLoader from '../../components/svg/loader'

const HostHuntPage = ({location}) => {
  const context = useContext(HostContext)
  const [hunt_id, setHuntId] = useState(null)
  const [images, setImages] = useState([])
  const [teamImages, setTeamImages] = useState([])
  const [active_image, setActiveImage] = useState({})
  const [loading, setLoading] = useState(true)
  const [deleting, setDeleting] = useState(false)
  const [updating, setUpdating] = useState(false)
  const [modalShow, setModalShow] = useState(false)
  const [deleteModalShow, setDeleteModalShow] = useState(false)
  // const [imageslider, setImageslider] = useState(false)
  const sliderContainer = React.createRef()
  const imageslider = React.createRef()

  useEffect(() => {
    const hash = location.hash.substring(1)
    console.log(`got hash: ${hash}`)
    setHuntId(hash)
  }, [location])

  var sliderSettings = {
    arrows: false,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    useTransform: true,
  }

  const refreshPhotos = () => {
    if(!updating){
      setUpdating(true)
      API.get(`hunt/${hunt_id}/photos`)
        .then(res => {
          const data = res.data
          let all_images = []
          all_images = all_images.concat(data.photos)
          all_images = all_images.concat(data.team_photos)
          setImages(all_images)
          setLoading(false)
          setUpdating(false)
        })
        .catch(function(error) {
          console.log(error)
        })
    }
  }

  useEffect(() => {
    const interval = setInterval(refreshPhotos, 2000)
    return () => {
      clearInterval(interval)
    }
  })

  useEffect(() => {
    if(sliderContainer.current){
      goToActivePhoto()
    }
  }, [active_image])

  useEffect(() => {
    if (modalShow) {
      sliderContainer.current.addEventListener(`touchstart`, touchStart)
      sliderContainer.current.addEventListener(`touchmove`, preventTouch, { passive: false })
    } 

    // cleanup function
    return () => {
      if (sliderContainer.current) {
        sliderContainer.current.removeEventListener(`touchstart`, touchStart)
        sliderContainer.current.removeEventListener(`touchmove`, preventTouch, { passive: false })
      }
    }
  }, [sliderContainer.current])

  const touchStart = (e) => {
    this.firstClientX = e.touches[0].clientX
  }

  const preventTouch = (e) => {
    const minValue = 5 // threshold

    let diff = e.touches[0].clientX - this.firstClientX

    // Vertical scrolling does not work when you start swiping horizontally.
    if (Math.abs(diff) > minValue) {
      // e.preventDefault()
      e.returnValue = false
      return false
    }
    return true
  }

  const setActivePhoto = (photo) => {
    setActiveImage(photo)
    setModalShow(true)
  }

  const goToActivePhoto = () => {
    let index = 0
    let photo_id = active_image.id
    images.map(function(image, i) {
      if (image.id == photo_id) {
        index = i
      }
    })
    imageslider.current.slickGoTo(index, true)
  }

  const deleteModalClose = () => {
    setDeleteModalShow(false)
    setModalShow(true)
  }

  const confirmDelete = (photo) => {
    setActiveImage(photo)
    setDeleteModalShow(true)
    setModalShow(false)
  }

  const deletePhoto = (id) => {
    console.log(`deleting photo ID: ${id}`)
    setDeleting(true)
    API.delete(`hunt/${hunt_id}/photos/${id}/`)
      .catch(function(error) {
        console.log(error)
      })
      .finally(() => {
        setActiveImage({})
        setDeleteModalShow(false)
        setModalShow(false)
        setLoading(false)
        setDeleting(false)
        refreshPhotos()
      })
  }

  return (
    <HostLoginWrapper>
      <h1 className="sr-only">Team Photo Gallery</h1>
      <div className={`photo-list row no-gutters mb-5`}>
        {loading && (
          <div className="col-6 text-center mt-5">
            <p>Loading photos...</p>
          </div>
        )}
        {images !== undefined &&
          images.map((photo, i) => (
            <div key={photo.id} className={`col-4 col-md-3 py-0 px-0 text-center`}>
              <Photo photo={photo} onClick={() => setActivePhoto(photo)} />
            </div>
          ))}
        {!loading && images.length == 0 && (
          <div className="col-12 text-center mt-5">
            <p className={`lead`}>
              <strong className={`d-block text-uppercase`}>No photos uploaded yet!</strong>
            </p>
          </div>
        )}
      </div>
      <Modal show={modalShow} onHide={() => setModalShow(false)} centered className="photo-modal" aria-labelledby="photo-details-modal-title">
        <h3 id="photo-details-modal-title" className="sr-only">Photo Details</h3>
        <Modal.Body className={`text-center text-uppercase px-0 py-0`}>
          <div ref={sliderContainer}>
            <Slider ref={imageslider} {...sliderSettings} className="images-slider">
              {images !== undefined &&
                images.map((photo, i) => (
                  <HostPhoto key={photo.id} photo={photo} onClick={confirmDelete} />
                ))}
            </Slider>
          </div>
          <button className={`btn btn-block btn-cancel btn-tiny text-uppercase`} onClick={() => setModalShow(false)}>
            Cancel
          </button>
        </Modal.Body>
      </Modal>
      <Modal show={deleteModalShow} onHide={deleteModalClose} centered aria-labelledby="delete-photo-modal-title">
        <Modal.Header closeButton>
          <Modal.Title id="delete-photo-modal-title" className={`text-center`}>
            Are you sure you want to <br />
            delete this photo?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className={``}>
          {loading ? (
            <p className={`text-center pt-4`}>
              <SVGLoader />
            </p>
          ) : (
            <button
              className={`btn btn-block btn-danger`}
              onClick={() => deletePhoto(active_image.id)}>
              Delete
            </button>
          )}
          <button className={`btn btn-block btn-cancel btn-tiny`} onClick={deleteModalClose}>
            Cancel
          </button>
        </Modal.Body>
      </Modal>
      
      <UploadPhotoModal hunt_id={hunt_id} />
      <PhotoUpload />
    </HostLoginWrapper>
  )
}

export default HostHuntPage