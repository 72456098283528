/**
 * Host Photo Upload Modal
 * 
 * Handles the upload of a photo from the host dashboard to a particular hunt gallery.
 */

import React, { useState, useContext, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal'
// import { getHostUser } from '../../../utils/host-auth'
import API from '../../../utils/api'
import HostContext from '../../../utils/host-context'
import imageCompression from 'browser-image-compression'
import SVGLoader from '../../svg/loader'

const UploadPhotoModal = ({hunt_id, handleUpload}) => {
  const context = useContext(HostContext)
  const [loading, setLoading] = useState(false)
  const buttonText = context.uploadImage !== `` ? context.uploadImage.name : `Choose Photo`

  let handleSubmit = (event) => {
    event.preventDefault()
    
    const name = context.user.first_name + ` ` + context.user.last_name
    const image = context.uploadImage
    setLoading(true)

    var compression_options = {
      maxSizeMB: 2,
      maxWidthOrHeight: 1920,
      useWebWorker: false,
    }

    // console.log(image)
    imageCompression(image, compression_options)
      .then(compressedFile => {
        console.log(compressedFile)
        var imageFile = new File([compressedFile], compressedFile.name, { type: compressedFile.type })
        const post_data = new FormData()
        post_data.append(`author`, name)
        post_data.append(`image`, imageFile)
        post_data.append(`hunt_id`, hunt_id)

        API.post(`hunt/${hunt_id}/photos/`, post_data)
          .then(res => {
            //console.log(res)
            console.log(context)
            context.handleUpload(res)
            // this.showAlert(`Photo Uploaded!`)
            // this.toggleRefresh()
          })
          .catch(function(error) {
            console.log(error)
          })
          .finally(() => {
            setLoading(false)
          })
      })
      .catch(function(error) {
        console.log(error)
      })
  }

  return (
    <Modal show={context.uploadModalShow} onHide={context.uploadModalClose} centered className="photo-upload-modal" aria-labelledby="upload-modal-title">
      <Modal.Header closeButton>
        <Modal.Title id="upload-modal-title" className={`text-center`}>Upload a Photo</Modal.Title>
      </Modal.Header>
      <Modal.Body className={``}>
        <form
          className={`form mb-3`}
          method="post"
          onSubmit={handleSubmit}>
          <p className={`title h5 text-center`}>{buttonText}</p>
          {loading ? (
            <p className={`text-center pt-4`}>
              <SVGLoader />
            </p>
          ) : (
            <input className={`btn btn-block btn-success`} type="submit" value="Upload" />
          )}
        </form>
        {!loading && (
          <button className={`btn btn-block btn-tiny btn-cancel`} onClick={context.uploadModalClose}>
            Cancel
          </button>
        )}
      </Modal.Body>
    </Modal>
  )
}

export default UploadPhotoModal
